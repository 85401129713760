import { useFormik } from 'formik';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { validateRegistrationForm } from '../../utils/validators';
import RegisterContext from '../../contexts/RegisterContext';
import AppContext from '../../contexts/AppContext';

const useRegisterForm = () => {
  const [showError, setShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { registerCard } = useContext(RegisterContext);
  const { recaptchaRef, selectedLanguage } = useContext(AppContext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromSearchParams, setFromSearchParams] = useState<boolean>(false);

  const TERMS_GE = process.env.REACT_APP_TERMS_AND_CONTITIONS_GE;
  const TERMS_EN = process.env.REACT_APP_TERMS_AND_CONTITIONS_EN;
  const TERMS_RU = process.env.REACT_APP_TERMS_AND_CONTITIONS_RU;

  const form = useFormik({
    initialValues: {
      pid: '',
      firstName: '',
      lastName: '',
      dob: null,
      phone: '',
      email: '',
      address: '',
      cardNo: '',
      gender: null,
      citizenship: 'georgia',
    },
    validateOnMount: false,
    validateOnChange: true,
    validate: validateRegistrationForm,
    onSubmit: (values) => {
      setSubmitted(true);
      submitForm();
    },
  });

  useEffect(() => {
    if (searchParams.get('cardNo')) {
      setFromSearchParams(true);
      form.setFieldValue('cardNo', searchParams.get('cardNo')!);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams, form]);

  const onFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldTouched(e.target.name, true);
      form.handleChange(e);
    },
    [form]
  );

  const onTermsClick = useCallback(() => {
    if (selectedLanguage && selectedLanguage.key === 'en') {
      window.open(TERMS_EN);
    } else if (selectedLanguage && selectedLanguage.key === 'ru') {
      window.open(TERMS_RU);
    } else {
      window.open(TERMS_GE);
    }
  }, [selectedLanguage, TERMS_EN, TERMS_GE, TERMS_RU]);

  const submitForm = useCallback(async () => {
    if (termsAccepted) {
      recaptchaRef && (await recaptchaRef.current!.executeAsync());
      await registerCard(form.values);
    } else {
      setShowError(true);
    }
  }, [form.values, termsAccepted, registerCard, recaptchaRef]);

  return useMemo(
    () => ({
      form,
      onFieldChange,
      onTermsClick,
      submitForm,
      showError,
      submitted,
      setTermsAccepted,
      termsAccepted,
      fromSearchParams,
    }),
    [
      form,
      onFieldChange,
      onTermsClick,
      submitForm,
      showError,
      submitted,
      setTermsAccepted,
      termsAccepted,
      fromSearchParams,
    ]
  );
};

export default useRegisterForm;
