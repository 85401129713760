import classNames from 'classnames';
import React, {
  CSSProperties,
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './TextInput.module.scss';

interface TextInputProps {
  id: string;
  label?: string;
  name: string;
  type?: 'text' | 'email';
  required?: boolean;
  value?: string;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  infoText?: string;
  errorText?: string;
}

const TextInput: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<TextInputProps>>
  >
> = (props: TextInputProps) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.value) {
      setValue(props?.value);
      if (inputRef.current) {
        inputRef.current.value = props.value;
      }
    }
  }, [props.value]);

  const disabledClass = classNames({
    [styles.disabled]: props.disabled,
  });

  const inputClasses = classNames({
    [styles.inputWrapper]: true,
    [styles.hasError]: props.hasError,
  });

  return (
    <div className={styles.container} style={props.bodyStyle}>
      <div className="df fd">
        {props.label && (
          <label htmlFor={props.id} className={disabledClass}>
            {props.label}
          </label>
        )}
      </div>

      <div className={inputClasses}>
        <input
          id={props.id}
          type={props.type}
          required={props.required}
          name={props.name}
          ref={inputRef}
          autoComplete="off"
          style={props.style}
          onBlur={() => (props.onBlur ? props.onBlur() : null)}
          onFocus={() => (props.onFocus ? props.onFocus() : null)}
          onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : null)}
          onChange={(e) => (props.onChange ? props.onChange(e) : null)}
          placeholder={props.placeholder}
          defaultValue={value}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
        <div className={styles.errorText}>
          {props.errorText || 'გთხოვთ შეავსოთ ველი'}
        </div>
      </div>
    </div>
  );
};

TextInput.defaultProps = {
  disabled: false,
  hasError: false,
  type: 'text',
};

export default TextInput;
