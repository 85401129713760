import axios, { AxiosInstance } from 'axios';

export default class RequestHelper {

  public static KARI_API = process.env.REACT_APP_KARI_API;

  private static _kari: AxiosInstance | null;


  private static setInterceptor(instance: AxiosInstance, catchResponse: boolean = true) {
    if (catchResponse) {
      instance.interceptors.response.use(
        (response) => response,
        (error) => {
          return Promise.reject(error.response.data?.message);
        }
      );
    }
  }

  static get kari() {
    if (!this._kari) {
      this._kari = axios.create({
        baseURL: this.KARI_API,
        headers: {
          'Project-Key': 'CAS'
        }
      });
      this.setInterceptor(this._kari);
    }

    return this._kari;
  }

}
