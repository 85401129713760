import React, { useContext, useEffect } from 'react';

import styles from './Registered.module.scss';
import { useNavigate } from 'react-router-dom';
import RegisterContext from '../../contexts/RegisterContext';
import { UseTranslate } from '../../hooks/Translate';

const playStore = require('../../assets/images/play-store.png');
const appStore = require('../../assets/images/app-store.png');
const smileIcon = require('../../assets/images/smile.png');
const { REACT_APP_GOOGLE_PLAY_URL, REACT_APP_APPLE_STORE_URL } = process.env;

const Registered = () => {
  const { registerSuccessfull } = useContext(RegisterContext);
  const navigate = useNavigate();
  const [translate] = UseTranslate();

  useEffect(() => {
    if (!registerSuccessfull) {
      navigate('/add-card');
    }
  }, [registerSuccessfull, navigate]);

  const navigateToAppStore = (isGoogle?: boolean) => () => {
    if (isGoogle) {
      window.open(REACT_APP_GOOGLE_PLAY_URL!, '_blank');
    } else {
      window.open(REACT_APP_APPLE_STORE_URL!, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <img src={smileIcon} alt="Success" />
        </div>
        <div className={styles.title}>
          {translate('loyalty.registered.success')}
        </div>
        <div className={styles.description}>
          {translate('loyalty.registered.successDescription')}
        </div>
        <div className={styles.actions}>
          <div className={styles.action} onClick={navigateToAppStore()}>
            <img src={appStore} alt="App Store" />
          </div>
          <div className={styles.action} onClick={navigateToAppStore(true)}>
            <img src={playStore} alt="Play Store" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registered;
