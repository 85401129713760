import { TypeOptions, toast } from 'react-toastify';

export class ToastService {
  public static createToast = (message: string, type: TypeOptions) => {
    toast(message, {
      type: type,
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };
}
