import { LanguageKey } from '../types/enums';

export class LocalStorage {
  public static async setLanguage(language: string): Promise<void> {
    Promise.resolve(localStorage.setItem('language', language));
  }

  public static async getLanguage(): Promise<LanguageKey> {
    return Promise.resolve(localStorage.getItem('language') as LanguageKey);
  }
}
