import React, { useContext, useState } from 'react';

import styles from './LanguageSelector.module.scss';
import AppContext from '../../contexts/AppContext';
import { LanguageKey } from '../../types/enums';

const georgianFlag = require('../../assets/images/georgia.png');
const englishFlag = require('../../assets/images/britain.png');
const arrow = require('../../assets/images/arrow.png');

const LanguageSelector = () => {
  const { languages, selectedLanguage, setLanguage } = useContext(AppContext);
  const [languagesVisible, setLanguagesVisible] = useState(false);

  const languageKeys: Record<string, string> = {
    ge: georgianFlag,
    en: englishFlag,
  };

  const onLanguageSelect = () => {
    setLanguagesVisible(!languagesVisible);
  };

  return (
    <div className={styles.container} onClick={onLanguageSelect}>
      <div className={styles.infoContainer}>
        <div className={styles.flag}>
          <img
            width={25}
            height={16}
            src={languageKeys[selectedLanguage?.key || LanguageKey.GE]}
            alt="Language"
          />
        </div>
        <div className={styles.language}>{selectedLanguage?.title}</div>
        <div className={styles.arrow}>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      {languagesVisible && (
        <div className={styles.languageContainer}>
          {languages?.map((language) => (
            <>
              {language.enabled && (
                <div
                  key={language.key}
                  className={styles.languageAction}
                  onClick={() => setLanguage(language)}
                >
                  {language.title}
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
