import React, { memo, useContext, useEffect } from 'react';
import RegistrationForm from '../../components/registration-form/RegistrationForm';

import styles from './AddCard.module.scss';
import Modal from '../../components/modal/Modal';
import OtpVerification from '../../components/opt-verification/OtpVerification';
import Banner from '../../components/banner/Banner';
import RegisterContext from '../../contexts/RegisterContext';
import { UseTranslate } from '../../hooks/Translate';
import AppContext from '../../contexts/AppContext';
import isMobile from 'is-mobile';
import Slider from '../../components/slider/Slider';

const AddCard = memo(() => {
  const { otpVerificationVisible, toggleOtpVerification, resetState } =
    useContext(RegisterContext);
  const { banners } = useContext(AppContext);

  const [translate] = UseTranslate();

  useEffect(() => {
    resetState();
  }, [resetState]);

  return (
    <>
      <div className={styles.container}>
        {isMobile() && banners && (
          <Slider dots animate slidesPerView={1}>
            <Banner
              image={banners?.imagesFirst}
              mobileImage={banners?.appImagesFirst}
              navigationUrl={banners?.linkFirst}
            />
            <Banner
              image={banners?.imagesSecond}
              mobileImage={banners?.appImagesSecond}
              navigationUrl={banners?.linkSecond}
            />
          </Slider>
        )}
        {!isMobile() && (
          <Banner
            image={banners?.imagesFirst}
            mobileImage={banners?.appImagesFirst}
            navigationUrl={banners?.linkFirst}
          />
        )}
        <div className={styles.formContainer}>
          <RegistrationForm />
        </div>
        {!isMobile() && (
          <Banner
            image={banners?.imagesSecond}
            mobileImage={banners?.appImagesSecond}
            navigationUrl={banners?.linkSecond}
          />
        )}
      </div>

      <Modal
        visible={otpVerificationVisible}
        onClose={() => toggleOtpVerification()}
        title={translate('loyalty.verification')}
        showClose
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        content={<OtpVerification />}
      />
    </>
  );
});

export default AddCard;
