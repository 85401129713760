import React, { useContext, useState } from 'react';
import { TfiReload } from 'react-icons/tfi';

import styles from './OtpVerification.module.scss';
import cn from 'classnames';
import Button from '../button/Button';
import OtpCode from '../otp-container/OtpCode';
import Countdown from '../Countdown';
import { useNavigate } from 'react-router-dom';
import RegisterContext from '../../contexts/RegisterContext';
import { UseTranslate } from '../../hooks/Translate';
import { ToastService } from '../../services/toast.service';

const OtpVerification = () => {
  const {
    registrationFormValues,
    requestId,
    validateOtp,
    resendOtp,
    setRegisterSuccessfull,
    setRegistrationFormValues,
  } = useContext(RegisterContext);
  const [showTimer, setShowTimer] = useState(true);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [translate] = UseTranslate();

  const resendAction = cn({
    [styles.active]: !showTimer,
    [styles.resendAction]: true,
  });

  const onResendCode = async () => {
    if (!showTimer) {
      await resendOtp(requestId!);
      setShowTimer(true);
    }
  };

  const validateOtpCode = async (otp: string) => {
    const validate = await validateOtp(requestId!, otp);
    if (typeof validate === 'object') {
      setRegistrationFormValues(null);
      setRegisterSuccessfull(true);
      navigate('/registered');
    } else {
      ToastService.createToast(
        translate('loyalty.otpVerification.codeInvalid'),
        'error'
      );
      setOtp('');
      setRegisterSuccessfull(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        {translate('loyalty.otpVerification.description')}:{' '}
        <strong className={styles.number}>
          {registrationFormValues?.phone}
        </strong>
      </div>
      <div className={styles.otpContainer}>
        <OtpCode onChange={(otp) => setOtp(otp)} />
        <div className={styles.resendCode}>
          <div className={styles.text}>
            {translate('loyalty.otpVerification.didNotReceiveCode')}
          </div>
          <div className={resendAction}>
            <div>
              <TfiReload />
            </div>
            <span onClick={onResendCode}>
              {translate('loyalty.otpVerification.receiveNewCode')} &nbsp;
              <>
                {showTimer && (
                  <Countdown
                    onFinish={() => {
                      setShowTimer(false);
                    }}
                    seconds={60}
                  />
                )}
              </>
            </span>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            text={translate('loyalty.otpVerification.confirm')}
            onClick={() => validateOtpCode(otp)}
            size={'wrapped'}
            disabled={otp.length < 4}
          />
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
