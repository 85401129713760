import React from 'react';

import styles from './Footer.module.scss';
import { UseTranslate } from '../../hooks/Translate';

const playStore = require('../../assets/images/play-store.png');
const appStore = require('../../assets/images/app-store.png');
const { REACT_APP_GOOGLE_PLAY_URL, REACT_APP_APPLE_STORE_URL } = process.env;

const Footer = () => {
  const [translate] = UseTranslate();

  const navigateToAppStore = (isGoogle?: boolean) => () => {
    if (isGoogle) {
      window.open(REACT_APP_GOOGLE_PLAY_URL!, '_blank');
    } else {
      window.open(REACT_APP_APPLE_STORE_URL!, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.company}>
        © {new Date().getFullYear()} {translate('loyalty.oriNabiji')}
      </div>
      <div className={styles.actions}>
        <div className={styles.action} onClick={navigateToAppStore(true)}>
          <img src={playStore} alt="Play Store" />
        </div>
        <div className={styles.action} onClick={navigateToAppStore()}>
          <img src={appStore} alt="App Store" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
