import { createBrowserRouter, redirect } from 'react-router-dom';
import AddCard from '../pages/add-card/AddCard';
import Root from '../pages';
import Registered from '../pages/registered/Registered';

const router = createBrowserRouter([
  {
    path: '/',
    Component: Root,
    children: [
      {
        index: true,
        loader: () => redirect('/add-card'),
      },
      {
        path: '/add-card',
        Component: AddCard,
      },
      {
        path: '/registered',
        Component: Registered,
      },
    ],
  },
]);

export default router;
