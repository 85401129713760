import React, { createContext, memo, useState } from 'react';
import { LoyaltyService } from '../services/loyalty.service';

interface IRegisterContext {
  termsAndConditionsVisible: boolean;
  registrationFormValues: ILoyaltyFormValues | null;
  otpVerificationVisible: boolean;
  requestId: string | null;
  registerSuccessfull: boolean;
  setRegisterSuccessfull: (success: boolean) => void;
  toggleTermsAndConditions: () => void;
  toggleOtpVerification: () => void;
  setRegistrationFormValues: (values: ILoyaltyFormValues | null) => void;
  registerCard: (values: ILoyaltyFormValues) => Promise<void>;
  validateOtp: (requestId: string, otp: string) => Promise<any>;
  resendOtp: (requestId: string) => Promise<void>;
  resetState: () => void;
}

const initialState: IRegisterContext = {
  termsAndConditionsVisible: false,
  otpVerificationVisible: false,
  registrationFormValues: null,
  requestId: null,
  registerSuccessfull: false,
  setRegisterSuccessfull: (sucess: boolean) => {},
  toggleTermsAndConditions: () => {},
  toggleOtpVerification: () => {},
  setRegistrationFormValues: (values: ILoyaltyFormValues | null) => {},
  registerCard: (values: ILoyaltyFormValues) => Promise.resolve(),
  validateOtp: (requestId: string, otp: string) => Promise.resolve(),
  resendOtp: (requestId: string) => Promise.resolve(),
  resetState: () => {},
};

const RegisterContext = createContext<IRegisterContext>(initialState);

export const RegisterProvider = memo((props: any) => {
  const [state, setState] = useState<IRegisterContext>({
    ...initialState,
    setRegisterSuccessfull: (success: boolean) => {
      setState((state) => ({
        ...state,
        registerSuccessfull: success,
      }));
    },
    toggleTermsAndConditions: () => {
      setState((state) => ({
        ...state,
        termsAndConditionsVisible: !state.termsAndConditionsVisible,
      }));
    },
    toggleOtpVerification: () => {
      setState((state) => ({
        ...state,
        otpVerificationVisible: !state.otpVerificationVisible,
      }));
    },
    setRegistrationFormValues: (values: ILoyaltyFormValues | null) => {
      setState((state) => ({
        ...state,
        registrationFormValues: values,
      }));
    },
    registerCard: async (values: ILoyaltyFormValues) => {
      const registerResponse = await LoyaltyService.registerCard(values);
      if (registerResponse.data) {
        setState((state) => {
          return {
            ...state,
            registrationFormValues: values,
            otpVerificationVisible: true,
            requestId: registerResponse.data.requestId,
          };
        });
      }
      return registerResponse;
    },
    validateOtp: async (requestId: string, otp: string) => {
      const validateResponse = await LoyaltyService.validateOtp(
        requestId,
        otp,
        false
      );
      return validateResponse;
    },
    resendOtp: async (requestId: string) => {
      await LoyaltyService.resendOtp(requestId);
    },
    resetState: () => {
      setState((state) => ({
        ...state,
        termsAndConditionsVisible: false,
        otpVerificationVisible: false,
        registrationFormValues: null,
        requestId: null,
        registerSuccessfull: false,
        verificationCount: null,
      }));
    },
  });

  return (
    <RegisterContext.Provider value={state}>
      {props.children}
    </RegisterContext.Provider>
  );
});

export default RegisterContext;
