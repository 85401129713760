import React from 'react';

import styles from './RegistrationForm.module.scss';
import TextInput from '../text-input/TextInput';
import Radio from '../radio/Radio';
import Button from '../button/Button';
import Check from '../check/Check';
import DatePicker from 'react-datepicker';
import { subYears } from 'date-fns';
import { UseTranslate } from '../../hooks/Translate';
import ReCaptchaLoader from '../ReCaptchaLoader';
import useRegisterForm from './useRegisterForm';

const MAX_DATE = subYears(new Date(), 18);

const RegistrationForm = () => {
  const [translate] = UseTranslate();
  const {
    form,
    submitted,
    onFieldChange,
    setTermsAccepted,
    termsAccepted,
    showError,
    onTermsClick,
    fromSearchParams,
  } = useRegisterForm();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {translate('loyalty.registrationForm.title')}
      </div>
      <form onSubmit={form.handleSubmit} className={styles.form}>
        <ReCaptchaLoader />
        <div className={styles.fullName}>
          <TextInput
            id="firstName"
            label={translate('loyalty.registrationForm.firstName')}
            name="firstName"
            placeholder={translate('loyalty.registrationForm.firstName')}
            hasError={
              !!form.errors.firstName && (form.touched.firstName || submitted)
            }
            errorText={translate(form.errors.firstName || '')}
            onChange={onFieldChange}
          />
          <TextInput
            id="lastName"
            label={translate('loyalty.registrationForm.lastName')}
            name="lastName"
            hasError={
              !!form.errors.lastName && (form.touched.lastName || submitted)
            }
            errorText={translate(form.errors.lastName || '')}
            placeholder={translate('loyalty.registrationForm.lastName')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.input}>
          <div className={styles.pickerContainer}>
            <label>{translate('loyalty.registrationForm.birthDate')}</label>
            <div>
              <DatePicker
                wrapperClassName={styles.datePickerWrapper}
                name="dob"
                id="dob"
                scrollableYearDropdown
                showYearDropdown
                yearDropdownItemNumber={70}
                dropdownMode="scroll"
                maxDate={MAX_DATE}
                placeholderText={translate(
                  'loyalty.registrationForm.birthDate'
                )}
                className={styles.datePicker}
                selected={form.values.dob}
                onChange={(date) => form.setFieldValue('dob', date)}
              />
            </div>
            {!!form.errors.dob && (form.touched.dob || submitted) && (
              <>
                <div className={styles.errorText}>
                  {translate(form.errors.dob || '')}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.input}>
          <div className={styles.radioContainer}>
            <label>{translate('loyalty.registrationForm.citizenship')}</label>
            <div className={styles.radioGroup}>
              <Radio
                id="georgia"
                name="citizenship"
                value="georgia"
                checked
                label={translate('loyalty.registrationForm.georgia')}
                onChange={onFieldChange}
              />
              <Radio
                id="abroad"
                name="citizenship"
                value="abroad"
                label={translate('loyalty.registrationForm.other')}
                onChange={onFieldChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.input}>
          <TextInput
            id="pid"
            label={translate('loyalty.registrationForm.personalNumber')}
            name="pid"
            hasError={!!form.errors.pid && (form.touched.pid || submitted)}
            errorText={translate(form.errors.pid || '', 11)}
            placeholder={translate('loyalty.registrationForm.personalNumber')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.input}>
          <div className={styles.radioContainer}>
            <label>{translate('loyalty.registrationForm.gender')}</label>
            <div className={styles.radioGroup}>
              <Radio
                id="woman"
                name="gender"
                value={2}
                hasError={
                  !!form.errors.gender && (form.touched.gender || submitted)
                }
                defaultChecked
                label={translate('loyalty.registrationForm.woman')}
                onChange={onFieldChange}
              />
              <Radio
                id="man"
                name="gender"
                hasError={
                  !!form.errors.gender && (form.touched.gender || submitted)
                }
                value={1}
                label={translate('loyalty.registrationForm.man')}
                onChange={onFieldChange}
              />
            </div>
            {!!form.errors.gender && (form.touched.gender || submitted) && (
              <>
                <div className={styles.errorText}>
                  {translate(form.errors.gender || '')}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.input}>
          <TextInput
            id="phone"
            label={translate('loyalty.registrationForm.phoneNumber')}
            name="phone"
            hasError={!!form.errors.phone && (form.touched.phone || submitted)}
            errorText={translate(form.errors.phone || '', '5XX XXX XXX')}
            placeholder={translate('loyalty.registrationForm.phoneNumber')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.input}>
          <TextInput
            id="email"
            type="email"
            label={translate('loyalty.registrationForm.email')}
            name="email"
            hasError={!!form.errors.email && (form.touched.email || submitted)}
            errorText={translate(form.errors.email || '')}
            placeholder={translate('loyalty.registrationForm.email')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.input}>
          <TextInput
            id="address"
            label={translate('loyalty.registrationForm.address')}
            name="address"
            hasError={
              !!form.errors.address && (form.touched.address || submitted)
            }
            errorText={translate(form.errors.address || '')}
            placeholder={translate('loyalty.registrationForm.address')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.input}>
          <TextInput
            id="cardNo"
            label={translate('loyalty.registrationForm.cardNumber')}
            name="cardNo"
            value={form.values.cardNo}
            hasError={
              !!form.errors.cardNo && (form.touched.cardNo || submitted)
            }
            disabled={fromSearchParams}
            errorText={translate(form.errors.cardNo || '', 12)}
            placeholder={translate('loyalty.registrationForm.cardNumber')}
            onChange={onFieldChange}
          />
        </div>
        <div className={styles.termsAndConditions}>
          <Check
            id="confirmation"
            onChange={() => setTermsAccepted(!termsAccepted)}
            name="confirmation"
            label={
              <div className={styles.labelContainer}>
                {translate('loyalty.registrationForm.agree')}{' '}
                <span className={styles.termsModal} onClick={onTermsClick}>
                  {translate('loyalty.registrationForm.rules')}
                </span>
              </div>
            }
          />
          {showError && !termsAccepted && (
            <>
              <div className={styles.errorText}>
                {translate('loyalty.registrationForm.agreeTo')}
              </div>
            </>
          )}
        </div>
        <Button
          buttonType="submit"
          text={translate('loyalty.registrationForm.register')}
        />
      </form>
    </div>
  );
};

export default RegistrationForm;
