import React, { FC, useState } from 'react';
import OTPInput from 'react-otp-input';

import styles from './OtpCode.module.scss';

interface IOtpCode {
  onChange: (otp: string) => void;
}

const OtpCode: FC<IOtpCode> = ({ onChange }) => {
  const [{ otp, numInputs, separator, placeholder, inputType }, setConfig] =
    useState({
      otp: '',
      numInputs: 4,
      separator: '',
      minLength: 0,
      maxLength: 40,
      placeholder: '',
      inputType: 'text' as const,
    });

  const handleOTPChange = (otp: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
    onChange(otp);
  };

  return (
    <div className={styles.container}>
      <OTPInput
        inputStyle={styles.inputStyle}
        numInputs={numInputs}
        onChange={handleOTPChange}
        renderSeparator={<span>{separator}</span>}
        value={otp}
        placeholder={placeholder}
        inputType={inputType}
        renderInput={(props) => <input {...props} />}
        shouldAutoFocus
      />
    </div>
  );
};

export default OtpCode;
