import React from 'react';

import styles from './Header.module.scss';
import LanguageSelector from '../language-selector/LanguageSelector';
import Button from '../button/Button';
import { UseTranslate } from '../../hooks/Translate';
import { ReactComponent as Help } from '../../assets/svgs/help.svg';

const imageUrl = require('../../assets/images/logo.png');

const Header = () => {
  const [translate] = UseTranslate();

  return (
    <div className={styles.container}>
      <div className={styles.placeHolder}>
        <Button
          text={translate('loyalty.help')}
          onClick={() => window.open('http://m.me/2nabijiofficial', '_blank')}
          size={'wrapped'}
          iconLeft={<Help />}
        />
      </div>
      <div className={styles.logoContainer}>
        <img src={imageUrl} alt="2Nabiji Logo" />
      </div>
      <div className={styles.languageSelectorContainer}>
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Header;
