import React, { CSSProperties, FC } from 'react';

import cn from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps {
  type?: 'primary' | 'secondary' | 'additional';
  round?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  size?: 'full' | 'wrapped' | number;
  alignIcon?: boolean;
  iconsStyle?: CSSProperties;
  showBorder?: boolean;
}

const Button: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<ButtonProps>>
  >
> = (props: ButtonProps) => {
  const btn = cn({
    [styles.btn]: true,
    [styles.disabled]: props.disabled,
    [styles[props.type ? props.type : '']]: true,
    [styles.round]: props.round,
  });

  const iconLeft = cn({
    [styles.icon]: true,
    [styles.iconLeftAlign]: !props.round && props.alignIcon,
  });

  const iconRight = cn({
    [styles.icon]: true,
    [styles.iconRightAlign]: !props.round && props.alignIcon,
  });

  const style = {
    ...props.style,
    maxWidth:
      props.size && props.size === 'full'
        ? '100%'
        : props.size === 'wrapped'
        ? 'fit-content'
        : props.size + 'px',
  };
  if (!props.showBorder) {
    style.border = 'none';
  }

  return (
    <button
      id="commonButton"
      type={props.buttonType}
      style={style}
      className={btn}
      onClick={() =>
        props.onClick && !props.disabled ? props.onClick() : null
      }
    >
      {props.iconLeft ? (
        <span className={iconLeft} style={props.iconsStyle}>
          {props.iconLeft}
        </span>
      ) : (
        ''
      )}
      {props.text ? <div className={styles.text}>{props.text}</div> : null}
      {props.iconRight ? (
        <span className={iconRight} style={props.iconsStyle}>
          {props.iconRight}
        </span>
      ) : (
        ''
      )}
    </button>
  );
};

Button.defaultProps = {
  type: 'primary',
  disabled: false,
  size: 'full',
  alignIcon: false,
};

export default Button;
