import React, { FC, useEffect, useState } from 'react';

interface ICountdown {
  seconds: number;
  onFinish: () => void;
}

const Countdown: FC<ICountdown> = ({ onFinish, seconds }) => {
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      onFinish();
    }
  }, [countdown, onFinish]);

  return (
    <>
      (00:
      {countdown.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
      )
    </>
  );
};

export default Countdown;
