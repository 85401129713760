export const paddingInlineEnd = (state: boolean, scroll: boolean): void => {
  if (state && scroll) {
    const t = document.createElement('div');
    t.style.position = 'absolute';
    t.style.top = '-9999px';
    t.style.width = '50px';
    t.style.height = '50px';
    t.style.overflow = 'scroll';
    document.body.appendChild(t);
    const scrollWidth = t.offsetWidth - t.clientWidth;
    document.body.removeChild(t);

    document.body.style.paddingInlineEnd = `${scrollWidth}px`;
  } else {
    document.body.style.paddingInlineEnd = '0px';
  }
};

export const hasScrollbar = function (): boolean {
  // The Modern solution
  if (typeof window.innerWidth === 'number')
    return window.innerWidth > document.documentElement.clientWidth;

  // rootElem for quirksmode
  const rootElem: any = document.documentElement || document.body;

  // Check overflow style property on body for fauxscrollbars
  let overflowStyle;

  if (typeof rootElem['currentStyle'] !== 'undefined')
    overflowStyle = rootElem['currentStyle'].overflow;

  overflowStyle =
    overflowStyle || window.getComputedStyle(rootElem, '').overflow;

  // Also need to check the Y axis overflow
  let overflowYStyle;

  if (typeof rootElem['currentStyle'] !== 'undefined')
    overflowYStyle = rootElem['currentStyle'].overflowY;

  overflowYStyle =
    overflowYStyle || window.getComputedStyle(rootElem, '').overflowY;

  const contentOverflows = rootElem.scrollHeight > rootElem.clientHeight;
  const overflowShown =
    /^(visible|auto)$/.test(overflowStyle) ||
    /^(visible|auto)$/.test(overflowYStyle);
  const alwaysShowScroll =
    overflowStyle === 'scroll' || overflowYStyle === 'scroll';

  return (contentOverflows && overflowShown) || alwaysShowScroll;
};
