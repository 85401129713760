import React, { FC, useContext } from 'react';

import styles from './Banner.module.scss';
import isMobile from 'is-mobile';
import AppContext from '../../contexts/AppContext';

interface IBanner {
  image?: string;
  mobileImage?: string;
  navigationUrl?: Record<string, string>;
}

const Banner: FC<IBanner> = ({ image, mobileImage, navigationUrl }) => {
  const { selectedLanguage } = useContext(AppContext);
  const { REACT_APP_FILE_API } = process.env;

  const imageUrl = isMobile()
    ? mobileImage
      ? `${REACT_APP_FILE_API}/files/download/${mobileImage}`
      : null
    : image
    ? `${REACT_APP_FILE_API}/files/download/${image}`
    : null;

  const navigateToUrl = () => {
    if (navigationUrl && selectedLanguage && selectedLanguage?.key) {
      window.open(navigationUrl[selectedLanguage?.key], '_blank');
    }
  };

  return (
    <>
      {imageUrl && (
        <div
          className={`${styles.bannerContainer} slide`}
          onClick={navigateToUrl}
        >
          <div className={styles.banner}>
            <img className={styles.bannerImage} src={imageUrl} alt="Banner" />
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
