export const validateRegistrationForm = (
  values: ILoyaltyFormValues,
  force?: boolean
) => {
  const errors: Record<string, any> = {};

  const registrationFormFields: Record<string, any> = {
    pid: {
      required: true,
      minLength: 11,
    },
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    dob: {
      required: true,
    },
    phone: {
      required: true,
      validator: /^5\d{8}$/,
    },
    email: {
      required: false,
      email: true,
    },
    address: {
      required: true,
    },
    cardNo: {
      required: true,
      minLength: 12,
    },
    gender: {
      required: true,
    },
  };

  for (const key in registrationFormFields) {
    const element = registrationFormFields[key];
    const currentValue = values[key as keyof typeof values];
    if (element.required && !currentValue) {
      errors[key] = 'loyalty.registrationForm.required';
    } else if (
      values.email &&
      element.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors[key] = 'loyalty.registrationForm.incorrectEmail';
    } else if (
      element.minLength &&
      currentValue &&
      currentValue.toString().length !== element.minLength
    ) {
      if (key === 'pid' && values.citizenship === 'abroad') {
      } else {
        errors[key] = `loyalty.registrationForm.numberValidation`;
      }
    } else if (element.validator && !element.validator.test(currentValue)) {
      errors[key] = `loyalty.registrationForm.invalidFormat`;
    }
  }

  return errors;
};
