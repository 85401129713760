import { useCallback, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { useLocalStorage } from './LocalStorage';

//let keys: string = '';
export const UseTranslate = () => {
  const { keywords, selectedLanguage } = useContext(AppContext);
  const [storedLanguage] = useLocalStorage('language', '');

  const translate = useCallback(
    (key: string, placeHolder?: number | string) => {
      if (keywords && keywords.length) {
        const keyword = keywords?.find((keyword) => keyword.key === key);
        if (keyword) {
          const lang = Object.keys(selectedLanguage as object).length
            ? selectedLanguage
            : storedLanguage;
          let translation = keyword.translations
            ? keyword.translations[lang ? lang.key : 'ge']
            : undefined;

          if (translation && placeHolder) {
            translation = translation.replace('{0}', placeHolder.toString());
          }

          return translation ? translation : key;
        }
        return key;
      }
      return '';
    },
    [keywords, storedLanguage, selectedLanguage]
  );

  return [translate];
};
