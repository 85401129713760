import React, { useContext } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import AppContext from '../contexts/AppContext';

const ReCaptchaLoader = () => {
  const { setRecaptchaExpired, setRecaptchaLoaded, setRecaptchaRef } =
    useContext(AppContext);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const scriptOnLoad = () => {
    setRecaptchaLoaded(true);
    setRecaptchaRef(recaptchaRef);
  };

  const onRecaptchaChange = (token: string | null) => {
    if (token === null) {
      setRecaptchaExpired(true);
    }
  };

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        onChange={onRecaptchaChange}
        asyncScriptOnLoad={scriptOnLoad}
        sitekey="6LdDOh8pAAAAAJmbfga98TU5-94wkfVmGGLdpQmJ"
      />
    </>
  );
};

export default ReCaptchaLoader;
