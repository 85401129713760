import React, { CSSProperties, FC, useEffect } from 'react';

import cn from 'classnames';

import { FiArrowLeft, FiX } from 'react-icons/fi';

import styles from './Modal.module.scss';
import { isString } from 'validate.js';
import Backdrop from '../backdrop/Backdrop';
import { hasScrollbar, paddingInlineEnd } from '../../utils/scroll';

interface ModalProps {
  visible: boolean;
  id?: string;
  backdrop?: boolean;
  onClose: () => void;
  onBack?: (() => void) | null;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  stretch?: boolean;
  title?: string | JSX.Element;
  content: string | JSX.Element;
  footer?: string | JSX.Element;
  static?: boolean;
  modalHeaderStyle?: CSSProperties;
  showClose?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  noScrollbarChange?: boolean;
  zIndex?: number;
}

const Modal: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<ModalProps>>
  >
> = (props: ModalProps) => {
  useEffect(() => {
    if (props.noScrollbarChange) return;
    const scroll = hasScrollbar();
    document.body.style.overflow = props.visible ? 'hidden' : 'auto';
    paddingInlineEnd(props.visible, scroll);
  }, [props.visible, props.noScrollbarChange]);

  const container = cn({
    [styles.stretch]: props.stretch,
    [styles.container]: true,
    [styles.static]: props.static,
  });

  const wrapper = cn({
    [styles.modalWrapper]: props.static && props.visible,
  });

  const closeOnWrapperClick = (event: any) => {
    const targetClassName = event.target.className;
    if (isString(targetClassName) && targetClassName.includes('modalWrapper')) {
      props.onClose();
    }
  };

  return (
    <div className={wrapper} onClick={closeOnWrapperClick}>
      {props.visible ? (
        <>
          <Backdrop
            onClick={props.onClose}
            visible={props.backdrop ? props.backdrop : false}
            zIndex={props.zIndex}
          />
          <div className={container} style={props.containerStyle} id={props.id}>
            {props.showHeader ? (
              <div className={styles.header} style={props.modalHeaderStyle}>
                {props.onBack ? (
                  <div
                    className={styles.back}
                    onClick={() => (props.onBack ? props.onBack() : null)}
                  >
                    <FiArrowLeft />
                  </div>
                ) : null}
                <div
                  className={styles.title}
                  style={{ textAlign: props.onBack ? 'center' : 'left' }}
                >
                  {props.title}
                </div>
                {props.showClose && (
                  <div className={styles.close} onClick={() => props.onClose()}>
                    <FiX />
                  </div>
                )}
              </div>
            ) : null}
            <div className={styles.content} style={props.style}>
              {props.content}
            </div>
            {props.showFooter ? (
              <div className={styles.footer}>{props.footer}</div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

Modal.defaultProps = {
  visible: false,
  static: false,
  backdrop: true,
  showClose: true,
  showHeader: true,
  showFooter: true,
};

export default Modal;
