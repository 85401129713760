import RequestHelper from '../apis/RequestHelper';
import { getErrorCodeTranslation } from '../utils/errorCodeTranslations';
import { ToastService } from './toast.service';

export class LoyaltyService {
  public static async registerCard(
    formValues: ILoyaltyFormValues
  ): Promise<ILoyaltyCardResponse | any> {
    const currentLanguage = localStorage.getItem('language') || 'ge';

    try {
      const response = await RequestHelper.kari.post(
        '/cards/self/add',
        formValues
      );
      return response.data;
    } catch (err: any) {
      ToastService.createToast(
        getErrorCodeTranslation(err, currentLanguage),
        'error'
      );
      return err;
    }
  }

  public static async resendOtp(requestId: string): Promise<any> {
    const currentLanguage = localStorage.getItem('language') || 'ge';
    try {
      const response = await RequestHelper.kari.post('/cards/self/resend/otp', {
        requestId,
      });
      return response.data;
    } catch (err: any) {
      ToastService.createToast(
        getErrorCodeTranslation(err, currentLanguage),
        'error'
      );
      return err;
    }
  }

  public static async validateOtp(
    requestId: string,
    otp: string,
    showToast: boolean
  ): Promise<any> {
    const currentLanguage = localStorage.getItem('language') || 'ge';
    try {
      const response = await RequestHelper.kari.post(
        '/cards/self/validate/otp',
        {
          requestId,
          otp,
        }
      );
      return response.data.data;
    } catch (err: any) {
      if (showToast) {
        ToastService.createToast(
          getErrorCodeTranslation(err, currentLanguage),
          'error'
        );
      }
      return err;
    }
  }

  public static async getBanners(): Promise<any> {
    const currentLanguage = localStorage.getItem('language') || 'ge';
    try {
      const response = await RequestHelper.kari.get('/cards/banners');
      return response.data;
    } catch (err: any) {
      ToastService.createToast(
        getErrorCodeTranslation(err, currentLanguage),
        'error'
      );
      return err;
    }
  }
}
