import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';

import cn from 'classnames';
import styles from './Slider.module.scss';

interface SliderProps {
  dots?: boolean;
  animate?: boolean;
  slidesPerView?: number;
  children: ReactNode;
}

const Slider: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<SliderProps>>
  >
> = (props: SliderProps) => {
  const [pause, setPause] = useState<boolean>(false);
  const [stopAutoPlay, setStopAutoPlay] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const timer = useRef<NodeJS.Timer>();
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slidesPerView: props.slidesPerView,
    duration: props.animate ? 1000 : 0,
    initial: 0,
    slides: '.slide',
    dragStart: () => {
      setPause(true);
      changeAutoPlay();
    },
    dragEnd: () => {
      setPause(false);
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('mouseover', () => {
        setPause(true);
      });
      sliderRef.current.addEventListener('mouseout', () => {
        setPause(false);
      });
    }
  }, [sliderRef]);

  useEffect(() => {
    if (!stopAutoPlay) {
      timer.current = setInterval(() => {
        if (!pause && slider) {
          slider.next();
        }
      }, 5000);
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [pause, slider, stopAutoPlay]);

  const changeAutoPlay = () => {
    if (timer.current) {
      clearInterval(timer.current);
      setStopAutoPlay(true);
    }
  };

  return (
    <>
      <div className={styles.sliderWrapper}>
        <div ref={sliderRef} className={styles.keenSlider}>
          {props.children}
        </div>
      </div>
      {props.dots && slider && slider.details().size > 1 ? (
        <div className={styles.dots}>
          {[...Array(slider.details().size).keys()].map((idx) => {
            const dots = cn({
              [styles.dot]: true,
              [styles.active]: currentSlide === idx,
            });

            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                  changeAutoPlay();
                }}
                className={dots}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};

Slider.defaultProps = {
  dots: true,
  animate: true,
  slidesPerView: 1,
};

export default Slider;
