import React, { useCallback, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';
import Header from '../components/header/Header';
import { ToastContainer } from 'react-toastify';
import Footer from '../components/footer/Footer';
import AppContext from '../contexts/AppContext';

const Root = () => {
  const { loadConfig, loadBanners } = useContext(AppContext);

  const onLoadConfig = useCallback(async () => {
    await loadConfig();
    await loadBanners();
  }, [loadConfig, loadBanners]);

  useEffect(() => {
    onLoadConfig();
  }, [onLoadConfig]);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.contentWrapper}>
        <Outlet />
      </div>
      <ToastContainer newestOnTop={false} rtl={false} pauseOnFocusLoss />
      <Footer />
    </div>
  );
};

export default Root;
