import React, { createContext, memo, useState } from 'react';
import RequestHelper from '../apis/RequestHelper';
import { IKeyword } from '../types/keyword.d';
import { ILanguage } from '../types/language.d';
import { LocalStorage } from '../services/localStorage.service';
import { LanguageKey } from '../types/enums';
import { LoyaltyService } from '../services/loyalty.service';
import ReCAPTCHA from 'react-google-recaptcha';

interface IAppContext {
  languages?: ILanguage[];
  keywords?: IKeyword[];
  banners?: IBanners | null;
  selectedLanguage?: Partial<ILanguage>;
  recaptchaLoaded: boolean;
  recaptchaExpired: boolean;
  recaptchaRef?: React.RefObject<ReCAPTCHA>;
  loadConfig: () => Promise<void>;
  loadBanners: () => Promise<void>;
  setLanguage: (language: ILanguage) => void;
  setBanners: (banners: IBanners) => void;
  setRecaptchaLoaded: (loaded: boolean) => void;
  setRecaptchaExpired: (expired: boolean) => void;
  setRecaptchaRef: (ref: React.RefObject<ReCAPTCHA>) => void;
}

const initialState: IAppContext = {
  languages: [],
  keywords: [],
  selectedLanguage: {},
  banners: null,
  recaptchaExpired: false,
  recaptchaLoaded: false,
  recaptchaRef: React.createRef<ReCAPTCHA>(),
  loadConfig: () => Promise.resolve(),
  loadBanners: () => Promise.resolve(),
  setLanguage: (language: ILanguage) => {},
  setBanners: (banners: IBanners) => {},
  setRecaptchaExpired: (expired: boolean) => {},
  setRecaptchaLoaded: (loaded: boolean) => {},
  setRecaptchaRef: (ref: React.RefObject<ReCAPTCHA>) => {},
};

const AppContext = createContext<IAppContext>(initialState);

export const AppProvider = memo((props: any) => {
  const [state, setState] = useState<IAppContext>({
    ...initialState,
    loadConfig: async () => {
      const url = '/configs?projectKey=loyalty';
      const res = await RequestHelper.kari.get(url);
      const { keywords, languages } = res.data.data;
      const cachedLanguage =
        (await LocalStorage.getLanguage()) || LanguageKey.GE;
      const selectedLanguage = languages.find(
        (language: ILanguage) => language.key === cachedLanguage
      );
      await LocalStorage.setLanguage(cachedLanguage);
      setState((state) => ({
        ...state,
        keywords,
        languages,
        selectedLanguage,
      }));
    },
    setLanguage: async (language: ILanguage) => {
      await LocalStorage.setLanguage(language.key);
      setState((state) => ({
        ...state,
        selectedLanguage: language,
      }));
      state.loadBanners();
    },
    loadBanners: async () => {
      const bannersResponse = await LoyaltyService.getBanners();
      const currentLanguage = await LocalStorage.getLanguage();
      const banners = bannersResponse.data.config;
      const formattedBanners: IBanners = {
        appImagesFirst: banners.appImagesFirst[currentLanguage]
          ? banners.appImagesFirst[currentLanguage][0]?.imageId
          : '',
        appImagesSecond: banners.appImagesSecond[currentLanguage]
          ? banners.appImagesSecond[currentLanguage][0]?.imageId
          : '',
        imagesFirst: banners.imagesFirst[currentLanguage]
          ? banners.imagesFirst[currentLanguage][0]?.imageId
          : '',
        imagesSecond: banners.imagesSecond[currentLanguage]
          ? banners.imagesSecond[currentLanguage][0]?.imageId
          : '',
        linkFirst: banners.linkFirst,
        linkSecond: banners.linkSecond,
      };
      setState((state) => ({
        ...state,
        banners: formattedBanners,
      }));
    },
    setBanners: async (banners: IBanners) => {
      setState((state) => ({
        ...state,
        banners,
      }));
    },
    setRecaptchaLoaded: (loaded: boolean) => {
      setState((state) => ({
        ...state,
        recaptchaLoaded: loaded,
      }));
    },
    setRecaptchaExpired: (expired: boolean) => {
      setState((state) => ({
        ...state,
        recaptchaExpired: expired,
      }));
    },
    setRecaptchaRef: (ref: React.RefObject<ReCAPTCHA>) => {
      setState((state) => ({
        ...state,
        recaptchaRef: ref,
      }));
    },
  });

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
});

export default AppContext;
