export const ErrorCodeTranslations: Record<string, any> = {
  API_ERROR_DUPLICATE_CARD_NO: {
    ge: 'მითითებული ბარათის ნომერი უკვე რეგისტრირებულია',
    en: 'Card number is already registered',
  },
  API_ERROR_CARD_NO_DOES_NOT_EXIST: {
    ge: 'მითითებული ბარათის ნომერი არ არსებობს',
    en: 'Card number does not exist',
  },
  API_ERROR_DIFFERENT_CUSTOMER_BY_PID_AND_PHONE: {
    ge: 'მითითებული პირადი და მობილურის ნომრები არ ემთხვევა ერთმანეთს',
    en: 'ID and Phone numbers do not match',
  },
  API_ERROR_DUPLICATE_PID: {
    ge: 'მითითებული პირადი ნომერი უკვე არსებობს',
    en: 'ID number is already registered',
  },
  API_ERROR_DUPLICATE_PHONE: {
    ge: 'მითითებული მობილურის ნომერი უკვე არსებობს',
    en: 'Phone number is already in use',
  },
  CARD_NOT_FOUND: {
    ge: 'მითითებული ბარათის ნომერი არ არსებობს',
    en: 'Card number does not exist',
  },
  INVALID_CARD: {
    ge: 'მითითებული ბარათის ნომერი არ არსებობს',
    en: 'Card number does not exist',
  },
  CARD_IS_ALREADY_ATTACHED_TO_CUSTOMER: {
    ge: 'მითითებული ბარათის ნომერი უკვე რეგისტრირებულია',
    en: 'Card number is already registered',
  },
  DIFFERENT_CUSTOMERS_FOUND: {
    ge: 'მითითებული პირადი და მობილურის ნომრები არ ემთხვევა ერთმანეთს',
    en: 'ID and Phone numbers do not match',
  },
  CUSTOMER_CAN_NOT_ADD_CARD: {
    ge: 'ბარათის რეგისტრაცია შეზღუდულია. გთხოვთ, დაგვიკავშირდეთ',
    en: 'Card registration is restricted. Please, contact support',
  },
  MAX_LIMIT_EXCEEDED: {
    ge: 'ბარათის რეგისტრაცია შეზღუდულია. გთხოვთ, დაგვიკავშირდეთ',
    en: 'Card registration is restricted. Please, contact support',
  },
  GENERAL_ERROR: {
    ge: 'დაფიქსირდა შეცდომა',
    en: 'Error occurred',
  },
};

export const getErrorCodeTranslation = (code: string, lang: string) => {
  const translation = ErrorCodeTranslations[code]
    ? ErrorCodeTranslations[code][lang]
    : null;

  if (translation) {
    return translation;
  } else {
    return ErrorCodeTranslations['GENERAL_ERROR'][lang];
  }
};
